@import url('https://fonts.googleapis.com/css2?family=Newsreader:opsz,wght@6..72,200&family=Poppins:wght@200&family=Roboto:wght@100&display=swap');

  @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@800&display=swap');

p , li{
  font-family: 'Poppins', sans-serif;
}

svg {
  width: 100%;
}

.logo {
  width: 200px;
}

@media screen and (max-width: 767px) {
  .logo {
    width: 130px;
  }
}

#path8886 {
  stroke: none !important;
}

.circle-green {
  display: inline-table;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  background-color: green;
  border-radius: 50%;
}

.circle-red {
  display: inline-table;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  background-color: red;
  border-radius: 50%;
}

.circle__content {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.ml-auto {
  margin-left: auto;
}
/* YourComponent.css */
.description {
  border: 1px solid black;
  width: 40%;
  margin-top: 20px;
  border-radius: 12px;
}

.parent-div-first {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.description div {
  display: flex;
  align-items: center;
  align-items: center;
  padding: 10px;
}

.text-bg {
  position: relative;
}

#tspan2160 {
  fill: white;
  cursor: pointer;
}
text {
  cursor: pointer;
}

.unsold {
  height: 30px;
  width: 5%;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}

.sold {
  height: 30px;
  width: 5%;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}

#tspan368 {
  cursor: pointer;
}

.text-bg:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(227, 54, 54, 0.6);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(13.5px);
  -webkit-backdrop-filter: blur(13.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.text-bg.bg-active:before {
  opacity: 1;
}

/* contact css */

.contact-container {
  background-color: #f8f9fa; /* Light background color */
  padding: 40px;
  border-radius: 10px;
  text-align: center;
}

.contact-heading {
  color: #343a40; /* Dark text color */
  font-size: 2rem;
}

.contact-info {
  background-color: #ffffff; /* White background color */
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.map-container {
  border-radius: 10px;
  overflow: hidden; /* Ensure the map stays within the container's rounded corners */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

/* about */

.about-banner {
  background-position: center;
  background-size: cover;
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 35vh;
}

.about-banner {
  position: relative;
  width: 100%;
  height: 35vh;
  overflow: hidden; /* Ensure the dark layer covers the entire banner */
}

.about-banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); 
  z-index: 1; 
}

.banner-title{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white; 
  z-index: 2; 
}

.banner-title svg{
  width: 30px !important;
  border: none;
}

.banner-content{
  display: grid;
  place-items: center;
  box-shadow: 0px 4px 44px rgba(211, 211, 211, 0.35);
 padding-top: 20px;
 padding-bottom: 10px;
  border-radius: 12px;
}

@media screen and (min-width: 991px) {
  .banner-content{
    padding: 30px;
  }
}

.banner-content ol li{
  padding-top: 15px;
  font-size: 20px;
}

@media screen and (max-width: 767px ) {
  .banner-content ol li{
    padding-top: 10px;
    font-size: 13px;
  }
}

.card{
  box-shadow: 0px 4px 44px rgba(211, 211, 211, 0.35);
  /* border: 1px solid black !important; */
}

@media screen and (min-width: 767px) {
  .features{
    display: none !important;
  }
}

/* footer css */

.footer-dark {
  padding:20px 0;
  color:#f0f9ff;
  background-color:#282d32;
}

.footer-dark h3 {
  margin-top:0;
  margin-bottom:12px;
  font-weight:bold;
  font-size:16px;
}

.footer-dark ul {
  padding:0;
  list-style:none;
  line-height:1.6;
  font-size:14px;
  margin-bottom:0;
}

.footer-dark ul a {
  color:inherit;
  text-decoration:none;
  opacity:0.6;
}

.footer-dark ul a:hover {
  opacity:0.8;
}

@media (max-width:767px) {
  .footer-dark .item:not(.social) {
    text-align:center;
    padding-bottom:20px;
  }
}

.footer-dark .item.text {
  margin-bottom:36px;
}

@media (max-width:767px) {
  .footer-dark .item.text {
    margin-bottom:0;
  }
}

.footer-dark .item.text p {
  opacity:0.6;
  margin-bottom:0;
}


.footer-dark .copyright {
  text-align:center;
  padding-top:24px;
  opacity:0.3;
  font-size:13px;
  margin-bottom:0;
}

.card-body a{
  color: white;
  text-decoration: none;
}

.text p{
  padding-top: 10px;
}

.abt-image {
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.abt-image img {
  width: 100%;
  height: auto;
  display: block;
}

.abt-image:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.abt {
  margin: 10px;
}

.abt-title{
  display: grid;
  font-weight: bold;
  font-size: 2em;
  place-items: center;
  font-weight: bolder;
  margin-top: 15px;
  margin-bottom: 15px;
}

.abt-desc{
  padding: 20px;
  box-shadow: 0px 4px 44px rgba(211, 211, 211, 0.35);
}

@media screen and (max-width: 767px) {
  .navbar-nav li{
    margin-top: 10px;
  }
}

h2{
  font-weight: 800;
  font-family: 'Raleway', sans-serif;
  color: blue !important;
}


.abt-desc p{
  line-height: 25px;
  padding-top: 15px;
}

.nav-item a{
  padding-left: 1rem;
  padding-right: 1rem;
  text-decoration: none;
  color: white;
}

.gallery-img img{
  width: 100%;
  border-radius: 12px;
}

/* .slick-arrow{
  background-color: #343a40 !important;
  border-radius: 50%;
} */

/* .slick-next::before , .slick-prev::before{
  color: black !important;
} */

  .slick-next , .slick-prev {
    display: none !important;
  }


@media screen and (max-width: 991px) {
  .abt-col{
    padding-top: 20px;
  }
}

.footer-titles{
  font-size: 25px;
}

.svg-plan{
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .svg-plan{
    justify-content: center;
  }
}


.svg-3 path{
  stroke: black !important;
}

.svg-3 text{
  fill: black ;
}


.sub-details {
  position: relative; 
}

.sub-details::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1; 
}


.sub-details {
  background: url(./assets/drone/b1.jpg) no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: auto;
}

.circle-info{
  position: relative;
  z-index: 2;
  max-width: 100%; 
  height: 200px;
  border: 2px solid red;
  background-color: white;
  border-radius: 50%;
  padding: 50px; 
  box-sizing: border-box; 
  text-align: center; 
}

/* .shimmer-wrapper {
  position: relative;
  overflow: hidden;
}

.shimmer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
} */

/* spinner */

.loader{
  height: 100vh;
  width: 100%;
  background: #343a40;
}

.loader .loading-text{
  color: #343a40;
}

.sub-loader-div{
  content: '';
  height: 100vh;
  width: 100%;
  background: #343a40;
}

@keyframes loader_5191 {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.square {
  background: #ddd;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -5px;
  margin-left: -5px;
}

#sq1 {
  margin-top: -25px;
  margin-left: -25px;
  animation: loader_5191 675ms ease-in-out 0s infinite alternate;
}

#sq2 {
  margin-top: -25px;
  animation: loader_5191 675ms ease-in-out 75ms infinite alternate;
}

#sq3 {
  margin-top: -25px;
  margin-left: 15px;
  animation: loader_5191 675ms ease-in-out 150ms infinite;
}

#sq4 {
  margin-left: -25px;
  animation: loader_5191 675ms ease-in-out 225ms infinite;
}

#sq5 {
  animation: loader_5191 675ms ease-in-out 300ms infinite;
}

#sq6 {
  margin-left: 15px;
  animation: loader_5191 675ms ease-in-out 375ms infinite;
}

#sq7 {
  margin-top: 15px;
  margin-left: -25px;
  animation: loader_5191 675ms ease-in-out 450ms infinite;
}

#sq8 {
  margin-top: 15px;
  animation: loader_5191 675ms ease-in-out 525ms infinite;
}

#sq9 {
  margin-top: 15px;
  margin-left: 15px;
  animation: loader_5191 675ms ease-in-out 600ms infinite;
}

/* custom tab-view  */
.nav-tabs {
  --bs-nav-tabs-border-width: 4px !important
}

